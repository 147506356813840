import React from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import './IndividualLite.scss'; // Ensure the SCSS file is correctly imported
import Logo from "../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from '../forms/BusinessLiteForms/components/ButtonGroup';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import { useTranslation } from '../../../store/context/TranslationContext';

const ApplyIndividualLiteContent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const queryParams = urlObject.search; // Get the query string

    return (
        <div className="apply-individual-lite">
            <div className='d-flex justify-content-center align-items-center'><LanguagePicker /></div>
            <div className="fv_indi-container form-content-tabs">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title">{t('Individual Plus')}</div>
                </div>
                <ul className="fv_indi-list">
                    <li className="fv_indi-item">
                        <FaRegUserCircle className="fv_indi-icon" />
                        {t('Ideal for individuals seeking a simple banking solution for personal use.')}
                    </li>
                    <li className="fv_indi-item">
                        <FaDollarSign className="fv_indi-icon" />
                        {t('Offers reduced or no monthly maintenance fees to keep banking affordable.')}
                    </li>
                    <li className="fv_indi-item">
                        <FaMobileAlt className="fv_indi-icon" />
                        {t('Includes basic features such as online banking, a debit card, and mobile app access.')}
                    </li>
                    <li className="fv_indi-item">
                        <FaRegListAlt className="fv_indi-icon" />
                        {t('May include transaction limits suitable for average personal use, reducing costs and complexity.')}
                    </li>
                    <li className="fv_indi-item">
                        <FaTools className="fv_indi-icon" />
                        {t('Streamlined application process and user-friendly account management tools.')}
                    </li>
                </ul>
                <div className='mt-4'>
                    <ButtonGroup
                        onNextClick={() => { navigate(`/individual-plus/login${queryParams}`) }}
                        onBackClick={() => { navigate(-1) }}
                        step={2}
                        asLabel={false}
                        loading={false}
                        limit={10}
                        hideBackButton
                    />
                </div>
            </div>
        </div>
    );
};

export default ApplyIndividualLiteContent;
